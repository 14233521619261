import { library, dom } from '@fortawesome/fontawesome-svg-core'

import {
 faEye,
  faQrcode,
  faChevronRight,
  faCogs,
  faPencilPaintbrush,
  faRocketLaunch,
  faCoins,
  faShareSquare,
  faMap,
  faClock,
  faImages,
  faConciergeBell,
  faPaperPlane,
  faCheckCircle,
  faExclamationCircle,
  faPlus,
  faEdit,
  faEllipsisV,
  faHome,
  faSortDown,
  faSortUp,
  faSort,
  faStore,
  faCreditCard,
  faTrash,
  faArchive,
  faSignOutAlt,
  faPhone,
  faMapMarkerAlt,
  faPen,
  faUtensils,
  faPlusCircle,
  faStoreSlash,
  faUserClock,
  faUmbrellaBeach,
  faSmoking,
  faHeat,
  faCarSide,
  faChild,
  faWheelchair,
  faBagsShopping,
  faGlassCheers,
  faBars,
  faWifi,
  faParking,
  faAirConditioner,
  faSeedling,
  faTicketAlt,
  faEyeSlash,
  faSave,
  faEnvelope,
  faUserCircle
} from '@fortawesome/pro-regular-svg-icons'

import {
  faLinkedin,
  faXTwitter,
  faPinterest,
  faFacebook,
  faInstagram,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'

// Brands icons
library.add(
  faEye,
  faQrcode,
  faChevronRight,
  faCogs,
  faPencilPaintbrush,
  faRocketLaunch,
  faCoins,
  faShareSquare,
  faMap,
  faClock,
  faImages,
  faConciergeBell,
  faPaperPlane,
  faCheckCircle,
  faExclamationCircle,
  faPlus,
  faEdit,
  faEllipsisV,
  faHome,
  faSortDown,
  faSortUp,
  faSort,
  faStore,
  faCreditCard,
  faTrash,
  faArchive,
  faSignOutAlt,
  faPhone,
  faMapMarkerAlt,
  faPen,
  faUtensils,
  faPlusCircle,
  faStoreSlash,
  faUserClock,
  faUmbrellaBeach,
  faSmoking,
  faHeat,
  faCarSide,
  faChild,
  faWheelchair,
  faBagsShopping,
  faGlassCheers,
  faBars,
  faWifi,
  faParking,
  faAirConditioner,
  faSeedling,
  faTicketAlt,
  faEyeSlash,
  faSave,
  faEnvelope,
  faUserCircle
)

// Brands icons
library.add(
  faLinkedin,
  faXTwitter,
  faPinterest,
  faFacebook,
  faInstagram,
  faTwitter
)

document.addEventListener('turbo:load', () => {
  // Replace any existing <i> tags with <svg> and set up a MutationObserver to
  // continue doing this as the DOM changes.
  dom.watch()
})
